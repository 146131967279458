@import url("typography.css");
:root {
	--main: #021c2f;
}

* {
	font-family: INter;
}
html {
	scroll-behavior: smooth;
}

.manrope {
	font-family: Manrope !important;
}
.rowdies {
	font-family: Rowdies !important;
}
.segoe {
	font-family: Segoe UI !important;
}
.inter {
	font-family: Inter !important;
}
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #ebf1ff;
	border-radius: 10px;
}

::-webkit-scrollbar {
	width: 8px;
	height: 10px;
}

::-webkit-scrollbar-thumb {
	background: var(--main);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: #1de9b6;
}

.otp-code__input {
	border-radius: 0.5rem;
	font-family: monospace;
	height: 50px;
	border: 2px solid #ccc;
	font-size: 2rem;
	text-align: center;
	transition: all 0.2s ease;
	color: #222;
	width: 3rem !important;
	margin: 0 0.5rem;
}

.btn p {
	display: none;
}

.btn:hover > p {
	display: block;
	-webkit-transition: 10s ease-out;
	-moz-transition: 2s ease-out;
	-o-transition: 2s ease-out;
	transition: 2s ease-out;
}
