/**  =====================
      Authentication css start
==========================  **/

.auth-wrapper {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	overflow: hidden;
	min-width: 100%;
	min-height: 100vh;
}

.auth-wrapper .auth-content {
	position: relative;
	width: 390px;
	padding: 15px;
	z-index: 5;
}

.auth-wrapper .auth-bg .r.s {
	width: 20px;
	height: 20px;
}

.auth-wrapper .auth-bg .r {
	position: absolute;
	width: 300px;
	height: 300px;
	border-radius: 50%;
}

.auth-wrapper .auth-bg .r.s:nth-child(2) {
	top: 150px;
	right: -150px;
	background: #04a9f5;
}

.auth-wrapper .auth-bg .r.s:nth-child(3) {
	left: -150px;
	bottom: 150px;
	background: #1de9b6;
}

.auth-wrapper .auth-bg .r:first-child {
	top: -100px;
	right: -100px;
	background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}

.auth-wrapper .auth-bg .r:last-child {
	left: -100px;
	bottom: -100px;
	background: linear-gradient(-135deg, #899fd4 0%, #a389d4 100%);
}

.auth-wrapper .auth-bg .r:nth-child(odd) {
	animation: floating 7s infinite;
}

.auth-wrapper .auth-bg .r:nth-child(even) {
	animation: floating 9s infinite;
}

.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	-webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
	box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
	border: none;
	margin-bottom: 30px;
	-webkit-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

@keyframes floating {
	from {
		transform: rotate(0deg) translate(-10px) rotate(0deg);
	}

	to {
		transform: rotate(360deg) translate(-10px) rotate(-360deg);
	}
}

/**====== Authentication css end ======**/
