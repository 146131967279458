@font-face {
  font-family: Rowdies;
  src: url("./assets/fonts/Rowdies-Regular.ttf");
}

@font-face {
  font-family: Manrope;
  src: url("./assets/fonts/regular.woff");
}

@font-face {
  font-family: Segoe UI;
  src: url("./assets/fonts/segoe.ttf");
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/inter.ttf");
}
